<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios"

/**
 * Form-mask component
 */
export default {
  page: {
    title: "Form Mask",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Form Mask",
      items: [
        {
          text: "Forms",
          href: "/",
        },
        {
          text: "Form Mask",
          active: true,
        },
      ],
      days: [],
      mondayAM: "",
      mondayPM: "",
      tuesdayAM: "",
      tuesdayPM: "",
      wednesdayAM: "",
      wednesdayPM: "",
      thursdayAM: "",
      thursdayPM: "",
      fridayAM: "",
      fridayPM: "",
      saturdayAM: "",
      saturdayPM: "",
      sundayAM: "",
      sundayPM: "",
      loading: false
    };
  },
  methods: {
    async setTime() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/appstate/update-day-times`,
            [{
              "day": "monday",
              "beginTime": this.mondayAM,
              "endTime": this.mondayPM
            },
            {
              "day": "tuesday",
              "beginTime": this.tuesdayAM,
              "endTime": this.tuesdayPM
            },
            {
              "day": "wednesday",
              "beginTime": this.wednesdayAM,
              "endTime": this.wednesdayPM
            },
            {
              "day": "thursday",
              "beginTime": this.thursdayAM,
              "endTime": this.thursdayPM
            },
            {
              "day": "friday",
              "beginTime": this.fridayAM,
              "endTime": this.fridayPM
            },
            {
              "day": "saturday",
              "beginTime": this.saturdayAM,
              "endTime": this.saturdayPM
            },
            {
              "day": "sunday",
              "beginTime": this.sundayAM,
              "endTime": this.sundayPM
            }]
        );
        console.log(response)
        this.$toast.success("App State Updated");
      } catch (error) {
        this.$toast.error(error.response.data.message);
      }
    },
    async getTimes() {
      this.loading = true
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/appstate`
        );
        this.days = response.data;
        this.mondayAM = this.days.filter(data => data.day === "monday")[0].accessBeginTime.split("+")[0]
        this.tuesdayAM = this.days.filter(data => data.day === "tuesday")[0].accessBeginTime.split("+")[0]
        this.wednesdayAM = this.days.filter(data => data.day === "wednesday")[0].accessBeginTime.split("+")[0]
        this.thursdayAM = this.days.filter(data => data.day === "thursday")[0].accessBeginTime.split("+")[0]
        this.fridayAM = this.days.filter(data => data.day === "friday")[0].accessBeginTime.split("+")[0]
        this.saturdayAM = this.days.filter(data => data.day === "saturday")[0].accessBeginTime.split("+")[0]
        this.sundayAM = this.days.filter(data => data.day === "sunday")[0].accessBeginTime.split("+")[0]
        this.mondayPM = this.days.filter(data => data.day === "monday")[0].accessCloseTime.split("+")[0]
        this.tuesdayPM = this.days.filter(data => data.day === "tuesday")[0].accessCloseTime.split("+")[0]
        this.wednesdayPM = this.days.filter(data => data.day === "wednesday")[0].accessCloseTime.split("+")[0]
        this.thursdayPM = this.days.filter(data => data.day === "thursday")[0].accessCloseTime.split("+")[0]
        this.fridayPM = this.days.filter(data => data.day === "friday")[0].accessCloseTime.split("+")[0]
        this.saturdayPM = this.days.filter(data => data.day === "saturday")[0].accessCloseTime.split("+")[0]
        this.sundayPM = this.days.filter(data => data.day === "sunday")[0].accessCloseTime.split("+")[0]
         this.loading = false
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getTimes();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="loading">
        <b-spinner class="m-2 m-auto" variant="primary" role="status"></b-spinner>
    </div>
    <div class="row" v-else>
      
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Application Locks</h4>
            <form @submit.prevent="setTime()">
              <div class="row">
                <div class="col-lg-6">
                  <div>
                    <div class="form-group mb-1">
                      <label>Monday (AM)</label>
                      <input
                        v-model="mondayAM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Tuesday (AM)</label>
                      <input
                        v-model="tuesdayAM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Wednesday (AM)</label>
                      <input
                        v-model="wednesdayAM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Thursday (AM)</label>
                      <input
                        v-model="thursdayAM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Friday (AM)</label>
                      <input
                        v-model="fridayAM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Saturday (AM)</label>
                      <input
                        v-model="saturdayAM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Sunday (AM)</label>
                      <input
                        v-model="sundayAM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div>
                    <div class="form-group mb-1">
                      <label>Monday (PM)</label>
                      <input
                        v-model="mondayPM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Tuesday (PM)</label>
                      <input
                        v-model="tuesdayPM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Wednesday (PM)</label>
                      <input
                        v-model="wednesdayPM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Thursday (PM)</label>
                      <input
                        v-model="thursdayPM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Friday (PM)</label>
                      <input
                        v-model="fridayPM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Saturday (PM)</label>
                      <input
                        v-model="saturdayPM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                    <div class="form-group mb-1">
                      <label>Sunday (PM)</label>
                      <input
                        v-model="sundayPM"
                        v-mask="'##:##'"
                        type="text"
                        class="form-control"
                      />
                      <span class="text-muted">e.g "hh:mm:ss"</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <b-button variant="primary" class="m-auto" type="submit">
                  <i class="ri-check-line align-middle mr-2"></i> Submit
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div>
</div>
  </Layout>
</template>
